/**
 * --------------------------------------------------------------------------
 * Licensed under MIT https://github.com/sitevision/envision/blob/master/LICENSE
 * --------------------------------------------------------------------------
 */

/**
 * Make use of external svgs possible in IE 11
 */
import 'svgxuse';
